
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { useRouter } from "vue-router";
import { ElTable } from "element-plus";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import _ from "lodash";
import { active } from "@/core/data/genericData";

export default defineComponent({
  name: "add-product-modal",
  components: {},
  //   methods: {
  //     onInput() {
  //     if (this.formData.brand_select.length > 1) {
  //       this.setBranddata("")
  //       console.log("Input")
  //     }
  //   }
  // },

  setup(props, { emit }) {
    const active_list_data = active;
    const formRef = ref<null | HTMLFormElement>(null);
    const addProductModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const metal_data = ref<Array<Category>>([]);
    const category_data = ref<Array<Category>>([]);
    const sub_category_data = ref<Array<Category>>([]);
    const store = useStore();
    const table_cols_data = ref<any>([]);
    const loadingHsn = ref<boolean>(false);
    const router = useRouter();
    const dialogAttrTableVisible = ref(false);
    const dialogStandardAttrTableVisible = ref(false);
    const edge_data = ref([
      {
        id: "Trim",
        name: "Trim",
      },
      {
        id: "Mill",
        name: "Mill",
      },
    ]);

    const thickness_min = ref(0);
    const thickness_max = ref(0);
    const length_min = ref(0);
    const length_max = ref(0);

    const cat_attr_count = ref(0);

    const brand_data = ref([]);
    const uom_data = ref([]);
    const standard_data = ref([]);
    const grade_data = ref([]);
    const branch = ref([]);
    const multipleSelection = ref<any>([]);
    const multipleSelection1 = ref();
    const multipleTableRef = ref<InstanceType<typeof ElTable>>();
    const multipleTableRef1 = ref<InstanceType<typeof ElTable>>();
    const displayTableRef = ref<InstanceType<typeof ElTable>>();
    const displayTableRef1 = ref<InstanceType<typeof ElTable>>();
    const table_data = ref<any>([]);
    const hsn_data = ref<any>([]);
    const manufacturer_data = ref([]);
    const brands_data = ref<any>([]);
    const plant_data = ref([]);
    const sa_table_data = ref<any>([]);
    const sa_table_cols_data = ref<any>([]);

    const standardJson = ref<any>([]);
    const structure_data = ref<any>([]);

    const categoryTree = ref([]);
    const treeProps = ref({
      children: "sub_attributes",
      label: "category_name",
    });
    const newTreeNode = ref<any>({});
    const newTreeValue = ref<any>([]);
    const treeRef = ref<null | HTMLFormElement>(null);

    const company = ref([]);

    getCategoryTree();

    interface Category {
      category_id: string;
      category_name: string;
      is_next_child_present: number;
    }
    // const OnClickCompany = async (compid) => {
    //   try {
    //         var values = { "company_id"  : compid, "page"  : 1, "records_per_page" : 10 }
    //         await store.dispatch(Actions.CUST_GET_BRANCH_LIST, values).then(({ data }) => {
    //         branch.value = data.result_list;
    //         console.log(branch.value)
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });

    //   } catch (e) {
    //     console.log(e);
    //   }
    // }

    const productdata = ref([]);
    const setProductDropdown = async (data) => {
      const db_data = {};
      //loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_GET_PRODUCT_SELECTBOX, values)
          .then(({ data }) => {
            productdata.value = data;
            //loading.value = false;
            console.log(productdata.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const compseller = ref([]);
    const setCompanySellerdata = async (data) => {
      const db_data = {};
      //loading.value = true;
      try {
        var values = {
          company_id: 0,
          search_term: data,
          page: 1,
          records_per_page: 10,
        };
        await store
          .dispatch(Actions.CUST_GET_COMPANY_LIST, values)
          .then(({ data }) => {
            compseller.value = data.result_list;
            //loading.value = false;
            console.log(compseller.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const setProductData = async (data) => {
      debugger;
      const db_data = {};
      var user = JSON.parse(JwtService.getToken());
      //loading.value = true;
      let cat_attr_values = ref<any>([]);
      let attr_values = structure_data.value.map((item) => {
        let cat_attr_value = {};
        if (item["input_value_required_yn"] === 1) {
          cat_attr_value = {
            category_attribute_setting_id:
              item["category_attribute_setting_id"],

            category_attribute_value: item["category_attribute_value"],
            input_value_type: item["input_value_type"],
          };
          if (item["category_attribute_value"])
            cat_attr_values.value.push(cat_attr_value);
        } else {
          item["subAttr"].map((child) => {
            cat_attr_value = {
              category_attribute_setting_id:
                child["category_attribute_setting_id"],

              category_attribute_value: child["category_attribute_value"],
              input_value_type: child["input_value_type"],
            };
            if (child["category_attribute_value"])
              cat_attr_values.value.push(cat_attr_value);
          });
        }
      });

      if (cat_attr_values.value.length < cat_attr_count.value) {
        Swal.fire({
          text: "Plese fill all the category attributes !",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      try {
        var values = {
          category_id: data.category_select,
          company_id: data.manufacturer_select,
          plant_id: data.plant_select ? data.plant_select : 0,
          brand_id: data.brand_select ? data.brand_select : 0,
          standard_id: data?.standard_select?.standard_id,
          standard_attribute_generic_id: data.standard_attr_select,
          category_attributes: cat_attr_values.value,
          hsn_code: data.hsn_select ? data.hsn_select : 0,
          user_id: user.user_id,
          active: 1,
        };
        await store
          .dispatch(Actions.CUST_ADD_PRODUCT, values)
          .then((data) => {
            debugger;
            loading.value = false;
            console.log(data);
            if (data.statusCode === 200 || data.statusCode === 201) {
              setTimeout(() => {
                loading.value = false;
                Swal.fire({
                  text: "Product Created successfully submitted!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({
                    path: "/products",
                  });
                });
              }, 2000);
            } else {
              Swal.fire({
                text: "Sorry, looks like there are some errors detected, please try again.",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        console.log(e);
      }
    };

    //const shape = ref([]);

    // const setShapedata = async (data) => {
    //   const db_data = {}
    //     loading.value = true;
    //     try {
    //       await store.dispatch(Actions.CUST_GET_PRODUCT_SHAPE, db_data).then(({ body }) => {
    //       shape.value = body.shape_list;
    //       //loading.value = false;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });

    //   } catch (e) {
    //     console.log(e);
    //   }

    // }

    const parent = ref([]);
    const setParentData = async (data) => {
      //loading.value = true;
      try {
        //var values = {"search_term" : data}
        await store
          .dispatch(Actions.CUST_GET_PARENT_CATEGORY)
          .then(({ data }) => {
            parent.value = data;
            //loading.value = false;
            console.log("Parent");
            console.log(parent.value);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const rangeValidator = async (value, rowIndex, row, min, max, fileld) => {
      console.log(value,fileld,min,max)
      let val = parseFloat(value);
      let minNum = parseFloat(min);
      let maxNum = parseFloat(max);
      let input: any = document.getElementsByClassName(fileld)[0].children[0];
      let warning: any = document.getElementById(fileld);
      warning.innerHTML = "";
      input.style.borderColor = "";
      if ((!/^\d*\.?\d*$/.test(value))||
          (value < minNum || val > maxNum || Number.isNaN(val))) {
        row.category_attribute_value = "";
        warning.innerHTML = "Please enter value between " + min + "-" + max;
        input.style.borderColor = "red";
      }
    };

    const making = ref([]);
    const setMakingdata = async (param) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_PRODUCT_MAKING, param)
          .then(({ body }) => {
            making.value = body.making_process_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const comp = ref([]);
    const setCompositiondata = async (param) => {
      const db_data = {};
      //loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_GET_PRODUCT_COMPOSITION, param)
          .then(({ body }) => {
            comp.value = body.composition_types_list;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const getStandardGradeJson = async () => {
      debugger;
      //Guard for plant select
      // if (formData.value["plant_select"] === "") {
      //   standardJson.value = [];
      //   return;
      // }
      //Guard clause for brand select brand is only required when there are brands available
      // else if (
      //   brands_data.value.length > 0 &&
      //   formData.value["brand_select"] === ""
      // ) {
      //   standardJson.value = [];
      //   return;
      // }
      var values = {
        category_id: formData.value["category_select"],
        company_id: 0,
        company_branch_id: 0,
        brand_id: 0,
        // company_id: formData.value["manufacturer_select"],
        // company_branch_id: formData.value["plant_select"],
        // brand_id:
        //   formData.value["brand_select"] != ""
        //     ? formData.value["brand_select"]
        //     : 0,
      };
      loading.value = true;
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_STANDARD_JSON_DATA, values)
        .then((data) => {
          if (data.status === 200) standardJson.value = data.data;
          else standardJson.value = [];
          loading.value = false;
        })
        .catch(({ e }) => {
          console.log(e);
        });
    };

    async function getAttributeStructure() {
      debugger;
      //Guard for plant select
      // if (formData.value["plant_select"] === "") {
      //   structure_data.value = [];
      //   return;
      // }
      //Guard clause for brand select brand is only required when there are brands available
      // else if (
      //   brands_data.value.length > 0 &&
      //   formData.value["brand_select"] === ""
      // ) {
      //   structure_data.value = [];
      //   return;
      // }

      var values = {
        category_id: formData.value["category_select"],
        company_id: 0,
        company_branch_id: 0,
        brand_id: 0,
        // company_id: formData.value["manufacturer_select"],
        // company_branch_id: formData.value["plant_select"],
        // brand_id:
        //   formData.value["brand_select"] != ""
        //     ? formData.value["brand_select"]
        //     : 0,
        //Pass 0 when there are no brands
      };
      loading.value = true;

      await store
        .dispatch(
          Actions.CUST_GET_PRODUCT_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID,
          values
        )
        .then(({ data }) => {
          debugger;
          loading.value = false;

          // UOM.value = data[0].uom_val;
          let formStructure = data.map((item) => {
            if (item.input_value_required_yn === 0) {
              let value = {};
              value["parentLabel"] = item.attribute_label;
              value["input_value_required_yn"] = item.input_value_required_yn;
              let childAttr = item.sub_attributes.map((child) => {
                let subAttr = {
                  input_value_type: child.input_value_type,
                  attribute_label: child.attribute_label,
                  category_attribute_setting_id:
                    child.category_attribute_setting_id,
                  category_attribute_value: "",
                  min_value: child.min_value,
                  max_value: child.max_value,
                  input_value_required_yn: child.input_value_required_yn,
                  attribute_values: child.attribute_values,
                  attribute_name: child.attribute_label.toLowerCase(),
                };
                if (child.input_value_type === "RANGE") {
                  subAttr["range"] = {
                    min: parseFloat(child.min_value),
                    max: parseFloat(child.max_value),
                  };
                }
                cat_attr_count.value++;
                return subAttr;
              });
              value["subAttr"] = childAttr;
              return value;
            } else {
              let value = {
                input_value_type: item.input_value_type,
                attribute_label: item.attribute_label,
                category_attribute_setting_id:
                  item.category_attribute_setting_id,
                category_attribute_value: "",
                min_value: 0,
                max_value: 0,
                input_value_required_yn: item.input_value_required_yn,
                attribute_values: item.attribute_values,
                attribute_name: item.attribute_label.toLowerCase(),
              };
              value["parentLabel"] = "";
              if (item.input_value_type === "RANGE") {
                // value["range"] = {
                  value.min_value = parseFloat(item.min_value);
                  value.max_value = parseFloat(item.max_value);
                // };
              }
              cat_attr_count.value++;
              return value;
            }
          });
          //      client_select: [
          //   {
          //     required: true,
          //     message: "Client is required",
          //     trigger: "change",
          //   },
          // ],
          structure_data.value = formStructure;
          let rule_stuctrue = {};
          formStructure.map((item) => {
            if (item.subAttr) {
              item.subAttr.map((attr) => {
                let label = attr.attribute_name;
                rule_stuctrue[label] = [
                  {
                    required: true,
                    message: attr.attribute_label + "  is required",
                    trigger: "change",
                  },
                ];
              });
            } else {
              let label = item.attribute_name;
              rule_stuctrue[label] = [
                {
                  required: true,
                  message: item.attribute_label + "  is required",
                  trigger: "change",
                },
              ];
            }
          });
          // rules.value = rule_stuctrue;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const brand = ref([]);
    const setBranddata = async (data) => {
      //loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_PRODUCT_BRAND, values)
          .then(({ data }) => {
            brand.value = data;
            //loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const formData = ref({
      standard_select: {},
      standard_attr_select: "",
      grade_select: "",
      metal_select: "",
      category_select: "",
      sub_category_select: "",
      manufacturer_select: "",
      //branch_select: "",
      brand_select: "",
      plant_select: "",
      uom_select: "",
      hsn_code: "",
      hsn_select: "",
      attr_select: "",
      sa_attr_select: "",
    });

    const rules = ref<any>({});

    const submit = async () => {
      loading.value = true;
      rules.value = {
        category_select: [
          {
            required: true,
            message: "Category is required",
            trigger: "change",
          },
        ],
        // manufacturer_select: [
        //   {
        //     required: true,
        //     message: "Manufacturer is required",
        //     trigger: "change",
        //   },
        // ],
        // plant_select: [
        //   {
        //     required: true,
        //     message: "Plant is required",
        //     trigger: "change",
        //   },
        // ],
        standard_select: [
          {
            required: true,
            type: "object",
            message: "Standard is required",
            trigger: "change",
          },
        ],
        standard_attr_select: [
          {
            required: true,
            message: "Standard Attribute is required",
            trigger: "change",
          },
        ],
      };
      if (brands_data.value.length > 0) {
        rules.value = {
          category_select: [
            {
              required: true,
              message: "Category is required",
              trigger: "change",
            },
          ],
          // manufacturer_select: [
          //   {
          //     required: true,
          //     message: "Manufacturer is required",
          //     trigger: "change",
          //   },
          // ],
          // plant_select: [
          //   {
          //     required: true,
          //     message: "Plant is required",
          //     trigger: "change",
          //   },
          // ],
          brand_select: [
            {
              required: true,
              message: "Brand is required",
              trigger: "change",
            },
          ],
          standard_select: [
            {
              required: true,
              type: "object",
              message: "Standard is required",
              trigger: "change",
            },
          ],
          standard_attr_select: [
            {
              required: true,
              message: "Standard Attribute is required",
              trigger: "change",
            },
          ],
        };
      }
      setTimeout(() => {
        if (!formRef.value) {
          loading.value = false;
          return;
        }

        formRef.value.validate(async (valid) => {
          if (valid) {
            await setProductData(formData.value);
          } else {
            loading.value = false;
            return false;
          }
        });
      }, 500);
    };

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCategoryTree() {
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_TREE)
        .then(({ data }) => {
          debugger;
          categoryTree.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    interface Tree {
      category_name: string;
      category_id: string;
    }
    const handleCheckChange = (
      data: Tree,
      checked: boolean,
      indeterminate: boolean
    ) => {
      debugger;
      formData.value["standard_select"] = "";
      manufacturer_data.value = [];
      if (checked) {
        treeRef.value?.setCheckedKeys([data.category_id], true);
        newTreeValue.value = [data.category_id];
        formData.value["category_select"] = data.category_id;
        newTreeNode.value = data;
        getAttributeList(data.category_id);
        // getStandardData(data.category_id);
        getStandardGradeJson();
        getAttributeStructure();
        getCompanyList(data.category_id);
      } else {
        if (data.category_id === newTreeValue.value[0]) {
          treeRef.value?.setCheckedKeys([], false);
          newTreeValue.value = [];
          formData.value["category_select"] = "";
          newTreeNode.value = data;
        } else {
          treeRef.value?.setCheckedKeys(newTreeValue.value, false);
          formData.value["category_select"] = newTreeValue.value[0]
            ? newTreeValue.value[0]
            : "";
          table_data.value = [];
          standard_data.value = [];
          standardJson.value = [];
          structure_data.value = [];
        }
      }
      console.log(data, checked, indeterminate);
    };
    // async function getCategoryData(id, type) {
    //   let params = {
    //     category_id: id,
    //   };

    //   await store
    //     .dispatch(Actions.CUST_GET_CATEGORY_NEW_LIST, params)
    //     .then(({ data }) => {
    //       debugger;
    //       if (id === 1) {
    //         metal_data.value = data ? data : [];
    //       } else if (type === "category") {
    //         category_data.value = data ? data : [];
    //       } else if (type === "subcat") {
    //         sub_category_data.value = data ? data : [];
    //         rules.value =
    //           sub_category_data.value.length <= 0
    //             ? {
    //                 metal_select: [
    //                   {
    //                     required: true,
    //                     message: "Metal type is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //                 category_select: [
    //                   {
    //                     required: true,
    //                     message: "Category is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //                 sub_category_select: [
    //                   {
    //                     required: false,
    //                     message: "Sub Category is required",
    //                     trigger: "change",
    //                   },
    //                 ],

    //                 uom_select: [
    //                   {
    //                     required: true,
    //                     message: "UOM is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //               }
    //             : {
    //                 metal_select: [
    //                   {
    //                     required: true,
    //                     message: "Metal type is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //                 category_select: [
    //                   {
    //                     required: true,
    //                     message: "Category is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //                 sub_category_select: [
    //                   {
    //                     required: true,
    //                     message: "Sub Category is required",
    //                     trigger: "change",
    //                   },
    //                 ],

    //                 uom_select: [
    //                   {
    //                     required: true,
    //                     message: "UOM is required",
    //                     trigger: "change",
    //                   },
    //                 ],
    //               };
    //       }
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // }

    function onCategoryChange(type) {
      debugger;
      let category =
        type === "subcat"
          ? category_data.value.filter((cat) => {
              if (
                cat.category_id === formData.value["category_select"] &&
                type === "subcat"
              )
                return true;
              else if (
                cat.category_id === formData.value["sub_category_select"] &&
                type === "subsubcat"
              )
                return true;
              else return false;
              // return cat.category_id === formProductData.value["category_select"];
            })
          : sub_category_data.value.filter((cat) => {
              if (
                cat.category_id === formData.value["category_select"] &&
                type === "subcat"
                // (type === "subcat"
                //   ? formData.value["category_select"]
                //   : formData.value["sub_category_select"])
              )
                return true;
              else if (
                cat.category_id === formData.value["sub_category_select"] &&
                type === "subsubcat"
              )
                return true;
              else return false;
              // return cat.category_id === formProductData.value["category_select"];
            });
      let cat_id = category.length > 0 ? category[0]?.category_id : "";
      console.log("rules:" + JSON.stringify(rules.value));

      if (category[0]?.is_next_child_present === 1 && type != "subsubcat") {
        // getCategoryData(cat_id, type);
      } else if (
        category[0]?.is_next_child_present === 0 &&
        type != "subsubcat"
      ) {
        sub_category_data.value = [];
        if (formData.value["sub_category_select"] != "")
          formData.value["sub_category_select"] = "";
      } else if (cat_id && category[0]?.is_next_child_present === 0) {
        getBrandtData(cat_id);
        getAttributeList(cat_id);
        getStandardData(cat_id);
      } else if (type != "subsubcat") {
        // getCategoryData(cat_id, type);
      }

      // rules.value =
      //   sub_category_data.value.length <= 0
      //     ? {
      //         metal_select: [
      //           {
      //             required: true,
      //             message: "Metal type is required",
      //             trigger: "change",
      //           },
      //         ],
      //         category_select: [
      //           {
      //             required: true,
      //             message: "Category is required",
      //             trigger: "change",
      //           },
      //         ],
      //         sub_category_select: [
      //           {
      //             required: false,
      //             message: "Sub Category is required",
      //             trigger: "change",
      //           },
      //         ],

      //         uom_select: [
      //           {
      //             required: true,
      //             message: "UOM is required",
      //             trigger: "change",
      //           },
      //         ],
      //       }
      //     : {
      //         metal_select: [
      //           {
      //             required: true,
      //             message: "Metal type is required",
      //             trigger: "change",
      //           },
      //         ],
      //         category_select: [
      //           {
      //             required: true,
      //             message: "Category is required",
      //             trigger: "change",
      //           },
      //         ],
      //         sub_category_select: [
      //           {
      //             required: true,
      //             message: "Sub Category is required",
      //             trigger: "change",
      //           },
      //         ],

      //         uom_select: [
      //           {
      //             required: true,
      //             message: "UOM is required",
      //             trigger: "change",
      //           },
      //         ],
      //       };
    }

    async function getBrandtData(id) {
      let params = {
        category_id: id,
      };

      await store
        .dispatch(Actions.CUST_GET_BRAND_BY_CATEGORY_LIST, params)
        .then(({ data }) => {
          brand_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getStandardData(id) {
      let params = {
        category_id: id,
        // category_standard_id: 0,
        // page: 1,
        // records_per_page: 100,
        // search_term: "",
      };

      await store
        .dispatch(Actions.CUST_GET_STANDARD_LIST_BY_CASTEGORY_DATA, params)
        .then(({ data }) => {
          debugger;
          standard_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getGradeData() {
      let params = {
        standard_attribute_id: 0,
        standard_id: formData.value["standard_select"],
        page: 1,
        records_per_page: 100,
        search_term: "",
      };

      await store
        .dispatch(Actions.CUST_GET_GRADE_LIST_DATA, params)
        .then(({ data }) => {
          debugger;
          grade_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getPlants() {
      //Guard if to clear plants dropdown when manufacturer is cleared
      if (formData.value["manufacturer_select"] === "") {
        plant_data.value = [];
        standardJson.value = [];
        structure_data.value = [];
        formData.value["plant_select"] = "";
        formData.value["standard_select"] = {};
        return;
      }
      let values = {
        company_id: formData.value["manufacturer_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 50,
      };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_PLANT_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          plant_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getAttributeList(id) {
      let params = {
        category_id: id,
      };

      await store
        .dispatch(Actions.CUST_GET_ATTRIBUTE_LIST_BY_CATEGORY_ID, params)
        .then(({ data }) => {
          debugger;
          table_cols_data.value = [];
          table_data.value = [];
          data = data.result_list;
          data[0].category_attribute_values_nested_json.map((item) => {
            if (item.sub_attributes.length > 0) {
              table_cols_data.value.push(item.category_attribute_label);
              item.sub_attributes.map((col) => {
                table_cols_data.value.push(col.category_attribute_label);
              });
            } else {
              table_cols_data.value.push(item.category_attribute_label);
            }
          });
          // let table_rows: Record<string, string | number>[];
          // let table_rows = Record<string, string | number>[];
          data.map((item) => {
            let value = {};
            value["category_attribute_mapping_id"] =
              item.category_attribute_mapping_id;
            item.category_attribute_values_nested_json.map((col) => {
              if (col.sub_attributes.length > 0) {
                value[col.category_attribute_label] =
                  col.category_attribute_value;
                col.sub_attributes.map((attr) => {
                  value[attr.category_attribute_label] =
                    attr.category_attribute_value;
                });
              } else {
                value[col.category_attribute_label] =
                  col.category_attribute_value;
              }
            });
            table_data.value.push(value);
          });
          // table_data = table_rows;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getStandardAttributeList() {
      let params = {
        standard_id: formData.value["standard_select"],
        // page: parseInt(paginationData.value["activePage"]),
        // records_per_page: parseInt(paginationData.value["perPage"]),
      };

      await store
        .dispatch(Actions.CUST_STANDARD_ATTRIBUTE_LIST_BY_STANDARD_ID, params)
        .then(({ data }) => {
          debugger;
          sa_table_cols_data.value = [];
          sa_table_data.value = [];
          data = data.result_list;
          data[0]?.standard_attribute_values_nested_json.map((item) => {
            if (item.sub_attributes.length > 0) {
              sa_table_cols_data.value.push(item.standard_attribute_label);
              item.sub_attributes.map((col) => {
                sa_table_cols_data.value.push(col.standard_attribute_label);
              });
            } else {
              sa_table_cols_data.value.push(item.standard_attribute_label);
            }
          });
          // let table_rows: Record<string, string | number>[];
          // let table_rows = Record<string, string | number>[];
          data.map((item) => {
            let value = {};
            value["standard_attribute_mapping_id"] =
              item.standard_attribute_mapping_id;
            item.standard_attribute_values_nested_json.map((col) => {
              if (col.sub_attributes.length > 0) {
                value[col.standard_attribute_label] =
                  col.standard_attribute_value;
                col.sub_attributes.map((attr) => {
                  value[attr.standard_attribute_label] =
                    attr.standard_attribute_value;
                });
              } else {
                value[col.standard_attribute_label] =
                  col.standard_attribute_value;
              }
            });
            sa_table_data.value.push(value);
          });
          // table_data = table_rows;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    const handleSelectionChange = (val: any[]) => {
      debugger;
      let index = val.length != 0 ? val.length - 1 : 0;
      // multipleTableRef.value!.setCurrentRow(val);
      multipleSelection.value = [];
      multipleSelection.value[0] = val[index];
      val.forEach((row) => {
        if (row === val[index]) {
          multipleTableRef.value!.toggleRowSelection(row, true);
        } else {
          multipleTableRef.value!.toggleRowSelection(row, false);
        }
      });
    };

    const handleSaSelectionChange = (val: any[]) => {
      debugger;
      let index = val.length != 0 ? val.length - 1 : 0;
      // multipleTableRef1.value!.setCurrentRow(val);
      multipleSelection1.value = [];
      multipleSelection1.value[0] = val[index];
      val.forEach((row) => {
        if (row === val[index]) {
          multipleTableRef1.value!.toggleRowSelection(row, true);
        } else {
          multipleTableRef1.value!.toggleRowSelection(row, false);
        }
      });
    };

    const getManufacturerData = async (data) => {
      debugger;
      const db_data = {
        search_term: "0",
      };
      loading.value = true;
      try {
        var values = { search_term: data };
        await store
          .dispatch(Actions.CUST_HSN_CATEGORY, values)
          .then(({ data }) => {
            hsn_data.value = data;
            console.log("HSN VALUE");
            console.log(hsn_data.value);
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    // async function getCompanyList(query: string) {
    //   debugger;
    //   if (query.length < 3) {
    //     return;
    //   }

    //   // let values = { trade_type: 1 };
    //   let values = { search_term: query };
    //   await store
    //     .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
    //     .then(({ data }) => {
    //       debugger;
    //       manufacturer_data.value = data;
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // }

    const getCompanyList = async (id) => {
      let values = {
        category_id: id,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_MANUFACTURER_LIST_DATA, values)
        .then(({ data }) => {
          debugger;
          manufacturer_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    async function getBrands() {
      // let values = { trade_type: 1 };
      //Guard if to clear brands dropdown when manufacturer is cleared
      if (formData.value["manufacturer_select"] === "") {
        brands_data.value = [];
        standardJson.value = [];
        structure_data.value = [];
        formData.value["brand_select"] = "";
        return;
      }
      let values = { company_id: formData.value["manufacturer_select"] };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_BRAND_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          brands_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    const setHsnData = async (query: string) => {
      debugger;
      const db_data = {
        search_term: "0",
      };
      loading.value = true;
      try {
        var values = { search_term: query };
        await store
          .dispatch(Actions.CUST_HSN_CATEGORY, values)
          .then(({ data }) => {
            hsn_data.value = data;
            console.log("HSN VALUE");
            console.log(hsn_data.value);
            loading.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const onContinue = () => {
      debugger;
      if (multipleSelection.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one Attribute",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        formData.value["attr_select"] =
          multipleSelection.value[0].category_attribute_mapping_id;
        dialogAttrTableVisible.value = false;
      }
    };
    const onSaContinue = () => {
      debugger;
      if (multipleSelection1.value.length === 0) {
        Swal.fire({
          text: "Please select atleast one Attribute",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        return false;
      } else {
        formData.value["sa_attr_select"] =
          multipleSelection1.value[0].standard_attribute_mapping_id;
        dialogStandardAttrTableVisible.value = false;
      }
    };

    onMounted(async () => {
      await setParentData("");
      await setBranddata("");
      await setProductDropdown("");
      await setMakingdata(making.value);
      await setCompanySellerdata(compseller.value);
      await setCompositiondata(comp.value);
      // await getCategoryData(1, "");
      await getUOM();
      //   await getCategoryTree();
      setCurrentPageBreadcrumbs("Add Product", []);
      //await OnClickCompany(branch.value)
    });
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      addProductModalRef,
      setParentData,
      setMakingdata,
      setCompositiondata,
      setProductData,
      setBranddata,
      //OnClickCompany,
      setCompanySellerdata,
      setProductDropdown,
      active_list_data,
      productdata,
      compseller,
      company,
      branch,
      brand,
      comp,
      making,
      parent,
      metal_data,
      category_data,
      sub_category_data,
      // getCategoryData,
      // onMetalChange,
      onCategoryChange,
      getBrandtData,
      brand_data,
      getStandardData,
      getAttributeList,
      standard_data,
      dialogAttrTableVisible,
      dialogStandardAttrTableVisible,
      table_cols_data,
      table_data,
      multipleSelection,
      multipleSelection1,
      multipleTableRef,
      multipleTableRef1,
      handleSelectionChange,
      handleSaSelectionChange,
      onContinue,
      grade_data,
      getGradeData,
      getUOM,
      uom_data,
      displayTableRef,
      displayTableRef1,
      categoryTree,
      treeProps,
      treeRef,
      newTreeValue,
      newTreeNode,
      handleCheckChange,
      hsn_data,
      setHsnData,
      manufacturer_data,
      getCompanyList,
      getBrands,
      brands_data,
      getStandardAttributeList,
      sa_table_data,
      sa_table_cols_data,
      onSaContinue,
      router,
      plant_data,
      thickness_min,
      thickness_max,
      length_min,
      length_max,
      edge_data,
      getPlants,
      standardJson,
      getStandardGradeJson,
      cat_attr_count,
      structure_data,
      rangeValidator,
      getAttributeStructure,
    };
  },
});
